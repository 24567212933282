import { v4 as uuidv4 } from 'uuid'

const FIELD_NAME = 'sessionId'

export function sessionIdGet(): string {
  let sessionId = sessionStorage.getItem(FIELD_NAME)
  if (!sessionId) {
    sessionId = uuidv4()
  }
  sessionStorage.setItem(FIELD_NAME, sessionId)

  return sessionId
}
