<script setup lang="ts">
import { ToasterDesktop } from '../model/toaster.api'
import { ToasterInfoMessage, ToasterErrorMessage } from './messages'

const clickToast = (toastId: symbol) => {
  const toast = ToasterDesktop.getToastItem(toastId)
  if (toast && toast.callback) {
    toast.callback()
  }
}

const removeToast = (toastId: symbol) => {
  clickToast(toastId)
  ToasterDesktop.deleteToast(toastId)
}

const stopToastTimer = (toastId: symbol) => {
  ToasterDesktop.stopToasterTimer(toastId)
}

const runToastTimer = (toastId: symbol) => {
  ToasterDesktop.runToasterTimer(toastId)
}
</script>
<template>
  <div
    class="toaster-list-wrapper fixed bottom-0 right-12 z-50 flex h-auto items-end lg:right-0 lg:translate-x-0"
  >
    <Transition name="fade">
      <TransitionGroup
        v-if="ToasterDesktop.toasts.length"
        name="toaster-list"
        tag="ul"
        class="max-h-[99vh] overflow-y-scroll overscroll-none pl-44 pt-68"
      >
        <li
          v-for="toast in ToasterDesktop.toasts"
          :key="toast.id"
          class="my-[10px] w-204 cursor-pointer pr-12 lg:w-372"
          @click="removeToast(toast.id)"
          @mouseover="stopToastTimer(toast.id)"
          @mouseout="runToastTimer(toast.id)"
        >
          <component
            :title="toast.title"
            :icon="toast.icon"
            :avatar-path="toast.avatar"
            :body="toast.message"
            class="toast-shadow"
            :is="toast.type === 'info' ? ToasterInfoMessage : ToasterErrorMessage"
          />
        </li>
      </TransitionGroup>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.toast-shadow {
  box-shadow: 0px 4px 28px 0px #0000008c;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.toaster-list-wrapper,
.toaster-list-move,
.toaster-list-enter-active,
.toaster-list-leave-active {
  transition: all 0.65s ease;
}

.toaster-list-enter-from,
.toaster-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.toaster-list-leave-active {
  position: absolute;
  right: 0;
}
</style>
