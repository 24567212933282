import { createI18n } from 'vue-i18n'
import en from '@/shared/locales/en'

export function i18nPlugin() {
  return createI18n({
    legacy: false,
    locale: 'en',
    messages: {
      en
    }
  })
}
