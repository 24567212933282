import { env } from '@/shared/config'
import { loadScript } from '@/shared/lib/loadScript'
import type { Gtag } from './gtag.types'

export class GtagClient {
  public disabledSend: boolean = false
  public loaded: boolean = false
  public isInit: boolean = false

  constructor() {
    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
  }

  public get instance(): Gtag {
    return window.gtag
  }

  public async init() {
    await loadScript(`https://www.googletagmanager.com/gtag/js?id=${env.GOOGLE_MEASUREMENT_ID}`)
    window.gtag('config', env.GOOGLE_MEASUREMENT_ID, {
      debug_mode: env.MODE === 'development',
      send_page_view: false
    })
    this.isInit = true
  }
}
