export function loadScript(src: string): Promise<void> {
  return new Promise(function (resolve) {
    const script = document.createElement('script')
    script.crossOrigin = 'anonymous'
    script.async = true
    script.defer = true
    script.onload = () => resolve()
    script.src = src
    document.head.appendChild(script)
  })
}
