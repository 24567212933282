import type { App, Plugin } from 'vue'
import { analytics } from '@/shared/plugins/analytics'
import { GtagClient } from './gtagClient.service'
import { GtagKey } from './gtag.config'
import type { IGtagPlugin } from './gtag.types'
import type { GtagGAPurchase, GtagGAPurchaseItem } from './googleAnalytics.types'
import { GoogleAnalytics } from './googleAnalytics.service'

export const gtagPlugin: Plugin = {
  install(app: App) {
    const gtagClient = new GtagClient()
    gtagClient.instance('js', new Date())

    const googleAnalytics = new GoogleAnalytics(gtagClient)
    analytics.adapterSet(googleAnalytics)

    const api: IGtagPlugin = {
      gtag: gtagClient.instance,
      /**
       * @link https://developers.google.com/gtagjs/reference/ga4-events#purchase
       */
      gaPurchase(props: GtagGAPurchase, items: GtagGAPurchaseItem[]) {
        gtagClient.instance('event', 'purchase', {
          ...props,
          items
        })
      }
    }
    app.config.globalProperties.$gtag = api
    app.provide(GtagKey, api)
  }
}
