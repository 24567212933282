export const ROUTE_NAV = {
  index: '/',
  banned: '/banned',
  cookiesPolicy: '/cookie-policy/',
  privacyPolicy: '/privacy-policy/',
  purchaseTerms: '/purchase-terms/',
  subscriptionTerms: '/subscription-terms/',
  termsAndConditions: '/terms-and-conditions/',
  doubleConnection: '/double-connection/',
  login: '/auth/login/',
  registrEmail: '/auth/registr-email/',
  registrName: '/auth/registr-name/',
  registrBefore: '/auth/registr-before/',
  registrSafe: '/auth/registr-safe/',
  registrSelfiePassport: '/auth/registr-selfie-passport/',
  registrPermissions: '/auth/registr-permissions/',
  registrSelfie: '/auth/registr-selfie/',
  registrPassport: '/auth/registr-passport/',
  registrBirthday: '/auth/registr-birthday/',
  registrPhotos: '/auth/registr-photos/',
  registrStories: '/auth/registr-stories/',
  registrRules: '/auth/registr-rules/',
  recovery: '/auth/recovery/',
  forgotPassword: '/auth/forgot-password/',
  recoveryPassword: '/auth/recovery-password/',
  cabVideoCall: '/cab/video-call/',
  cabMessages: '/cab/messages/',
  cabCallHistory: '/cab/call-history/',
  cabPrivateCall: '/cab/private-call/',
  cabProfile: '/cab/profile/',
  cabReverification: '/cab/profile/reverification/',
  cabProfileEdit: '/cab/profile/edit/',
  cabUploadNew: '/cab/upload-new/',
  cabRequestWithdrawal: '/cab/request-withdrawal/',
  cabWithdrawal: '/cab/withdrawal/',
  cabWithdrawalHistory: '/cab/withdrawal-history/',
  cabCoinsHistory: '/cab/coins-history/',
  cabStories: '/cab/stories',
  cabStoriesCreate: '/cab/stories/create',
  cabStory: '/cab/stories/:id',
  cabRateMatrix: '/cab/rate/',
  cabRateStatistics: '/cab/rate-statistics/',
  cabReModeration: '/cab/profile/remoderation/',
  cabReferral: '/cab/referral/',
  cabReferralDetails: '/cab/referral-details/',
  cabWeeklyTopStreamer: '/cab/weekly-top-streamer/'
}
