export const env = Object.freeze({
  HOST_NAME: 'hiti.live',
  PROJECT_NAME: import.meta.env.PKG.PROJECT_NAME,
  VERSION: import.meta.env.PKG.VERSION || '0.0.0',
  MODE: import.meta.env.MODE,

  AUTH_URL: import.meta.env.VITE_AUTH_URL,
  APP_URL: import.meta.env.VITE_APP_URL,
  SOCKET_URL: import.meta.env.VITE_SOCKET_URL || '',

  VERSION_BACKEND: import.meta.env.VITE_VERSION_BACKEND || '0',
  APPLICATION: import.meta.env.VITE_APPLICATION,
  WEBSOCKET_APPLICATION: import.meta.env.VITE_WEBSOCKET_APPLICATION || '',

  GOOGLE_MEASUREMENT_ID: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID,
  GOOGLE_ANALYTICS_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: import.meta.env.VITE_GOOGLE_OPTIMIZE_ID,
  GOOGLE_OAUTH_CLIENT_ID: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_TRANSLATE_KEY: import.meta.env.VITE_GOOGLE_TRANSLATE_KEY,

  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS || '',
  SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN || '',
  SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG || '',
  // SENTRY_PROJECT: import.meta.env.PROJECT_NAME,

  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY
})
