import amplitude from 'amplitude-js'
import { env } from '@/shared/config'

export class AmplitudeClient {
  public isInit: boolean = false
  public instance = amplitude.getInstance()

  public init() {
    amplitude.getInstance().init(env.AMPLITUDE_API_KEY, undefined, {
      includeReferrer: true,
      includeUtm: true
    })
    amplitude.getInstance().setVersionName(`${env.PROJECT_NAME}@${env.VERSION}`)
    this.isInit = true
  }
}
