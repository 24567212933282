import type { InjectionKey } from 'vue'
import type { IToasterPlugin } from './types'

/**
 * @public
 * @constant
 * @type {InjectionKey<IToasterPlugin>}
 * @description Injection key for the toaster plugin to be used with Vue's dependency injection system.
 * @example
 * import { ToasterKey } from './config'
 * import { ToasterKey } from '@/shared/symbols'
 * const $toaster = inject(ToasterKey)
 */
export const ToasterKey: InjectionKey<IToasterPlugin> = Symbol()

/**
 * @public
 * @constant
 * @type {number}
 * @description The base timeout duration in milliseconds for toasts to be displayed.
 */
export const BASE_TOAST_TIMEOUT = 5000

/**
 * @public
 * @constant
 * @type {number}
 * @description The base delay duration in milliseconds between consecutive toasts in the queue.
 */
export const BASE_TOAST_DELAY = 2000
